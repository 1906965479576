import { Injectable } from '@angular/core';
import { AlertRule } from '../../types/alert-rule.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class AlertRulesApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/alert-rules`;
  }

  async getAlertRules(): Promise<AlertRule[]> {
    return await this.get<AlertRule[]>('');
  }

  async createAlertRule(alertRule: AlertRule): Promise<void> {
    return await this.post('', alertRule);
  }

  async updateAlertRule(alertRule: AlertRule): Promise<void> {
    return await this.put('', alertRule);
  }

  async setAlertRuleIsActive(id: string, isActive: boolean): Promise<void> {
    return await this.patch(`${id}/is-active`, isActive);
  }

  async deleteAlertRule(id: string): Promise<void> {
    return await this.delete(id);
  }
}
