import { DeviceType } from '../enums/device-type.enum';

export class Global {
  static os = '';
  static isMacLike = false;
  static browserName = '';
  static browserVersion = '';
  static deviceType: DeviceType = DeviceType.desktop;
  static localeId = '';
  static mobileBreakpoint = 768;

  static initialize(localeId: string): () => void {
    return () => {
      Global.os = Global.detectOperationSystem();
      Global.isMacLike = navigator.userAgent.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
      Global.browserName = Global.detectBrowserName();
      Global.browserVersion = Global.detectBrowserVersion();
      Global.localeId = localeId;
    };
  }
  static detectPopupBlocker(): boolean {
    const popup = window.open('', '', 'height=100,width=100');
    if (!popup) return true;
    popup.close();
    return false;
  }

  private static detectOperationSystem(): string {
    if (navigator.userAgent.indexOf('Win') !== -1) return 'Windows';
    else if (navigator.userAgent.indexOf('Mac') !== -1) return 'Mac';
    else if (navigator.userAgent.indexOf('X11') !== -1) return 'UNIX';
    else if (navigator.userAgent.indexOf('Linux') !== -1) return 'Linux';
    else return '';
  }

  private static detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private static detectBrowserVersion() {
    const userAgent = navigator.userAgent;
    let tem: RegExpMatchArray;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent);
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
}
